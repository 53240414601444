.imgPrincipal {
    width: -webkit-fill-available;
}

@media (max-width: 768px) {
    .imgPrincipal {
        height: 300px;
    }
}

.metrics {
    padding: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    margin: 40px auto;
    text-align: center;
}

.icon-svg {
    width: 91px !important;
    margin: 20px auto;
}

.metrics {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 40px;
    max-width: 100%;
    align-items: center;
    justify-items: center;
}

.icon-metrics {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.separator-metrics {
    background-color: #264A80;
    width: 3px;
    height: 300px;
    margin: 0;
}

.value-metrics {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 64px !important;
    line-height: 75px !important;
    color: rgba(35, 164, 85, 0.68) !important;
}

.text-metrics {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px !important;
    line-height: 35px !important;
    color: rgba(38, 74, 128, 0.68) !important;
}

@media (max-width: 768px) {
    .metrics {
        grid-template-columns: 1fr;
    }

    .icon-metrics {
        margin: 20px 0;
    }

    .separator-metrics {
        width: 80%;
        height: 3px;
        margin: 20px 0;
    }

    .value-metrics {
        font-size: 48px !important;
        line-height: 55px !important;
    }

    .text-metrics {
        font-size: 24px !important;
        line-height: 30px !important;
    }
}

.swiper {
    width: 80%;
    height: 600px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.swiper-pagination-bullet {
    background-color: #264A80 !important;
    width: 15px !important;
    height: 15px !important;
    margin: 5px 5px !important;
}

.swiper-pagination-bullet-active {
    background-color: #264A80 !important;
}

.first-carrousel {
    height: auto;
    width: 90%;
    margin: 20px auto;
}

@media (max-width: 768px) {
    .first-carrousel {
        width: 100%;
        margin: 10px auto;
    }
}


.subject-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    pointer-events: none;
}

.subject-container-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.icon-landing {
    margin-bottom: 10px;
}

.subject-name-landing {
    padding: 0 60px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: rgba(211, 181, 116, 0.68) !important;
}

/* ################################################################################## */

.subjects-grid-grados {
    margin: 50px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.grados-image:hover {
    opacity: 1;
    filter: blur(10px);
    cursor: pointer;
}


.grados-subject {
    position: relative;
    width: 340px;
    height: 552px;
}

.grados-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.grados-subject-text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* ################################################################################## */

/* MySubjectsGrid.css */

.container-landing-tabs {
    margin: 20px auto;
    width: calc(100% - 47px);
    position: relative;
}

.tabs-landing {
    display: flex;
    margin-bottom: 20px;
}

.tab-landing {
    width: 25%;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
    margin-right: 10px;
}

.tab-landing.active {
    background-color: #264A80;
    color: #FFFFFF;
}

.content-container-landing {
    padding: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    top: calc(100% + 20px);
    box-shadow: 1px 9px 15px 6px rgba(0, 0, 0, 0.25);
}



.icon-landing-tabs {
    width: 50px;
    height: 50px;
    color: #264A80;
}

.icon-landing-tabs:hover {
    cursor: pointer;
    color: rgba(211, 181, 116, 0.68) !important;
}

.text-landing-tabs {
    padding: 10px 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: rgba(35, 164, 85, 0.68) !important;
    top: 87.5%;
}

.subject-container-landing-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 768px) {
    .tabs-landing {
        width: 100%;
        flex-direction: column;
        margin: 20px 0;
    }

    .tab-landing {
        margin: 10px 0;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .content-container-landing {
        top: 0;
    }

    .content-container-landing {
        padding: 20px;
        display: grid;
    }

}

/* ################################################################################## */

.title-landing {
    width: 100% !important;
    text-align: center;
    font-family: 'BebasNeue-Bold' !important;
    margin: 45px auto;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 50px !important;
    line-height: 60px !important;
    color: #264A80 !important;
}

/* ################################################################################## */
.last-new {
    margin: auto;
    width: 80% !important;
}

.img-center-landing {
    margin: auto;
    width: 100% !important;
    align-items: center;
    text-align: center;
    justify-items: center;
}

.img-landing-maps {
    display: block;
    margin: auto;
    margin-bottom: 100px;
}

.img-landing-maps:hover {
    opacity: 5;
    cursor: pointer;
    filter: blur(2px);
}

@media (max-width: 768px) {
    .img-landing-maps {
        width: 90%;
        height: 250px;
        max-width: 100%;
        margin-bottom: 50px;
    }

    .logo-overlay {
        width: 50%;
        top: 5%;
        left: 5%;
    }
}

.logo-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}