.titleNews {
    font-family: 'BebasNeue-Regular' !important;
    font-size: 50px !important;
    color: #264A80 !important;
}
.carousel-indicators [data-bs-target] {
    border-radius: 50% !important;
    width: 15px !important;
    height: 15px !important;
    background-color: white !important;
    border: 2px solid #264A80 !important;
    margin-top: auto !important;
}
.carousel-indicators {
    bottom: -41px !important;
}

.carousel-indicators .active {
    width: 17px !important;
    height: 17px !important;
    background-color: #264A80 !important;
}
.subtitleNews {
    font-family: 'BebasNeue-Regular' !important;
    font-size: 34px !important;
    color: #264A80 !important;
}

.titleCarouseNews {
    font-family: 'Roboto-Bold' !important;
    font-size: 32px !important;
    font-weight: bolder !important;
}

.descriptionCarouseNews {
    font-family: 'Roboto-Light' !important;
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: lighter !important;
}
.dateCarouseNews {
    font-family: 'Roboto-Regular';
    font-size: 24px;
    font-weight: lighter !important;
}