.styleCards {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: rgba(117, 117, 117, 0.6) !important;
    padding: 65% 10% !important;
    text-align: center !important;
    height: 100% !important;
}

.styleCards:hover{
    background-color: rgba(23, 23, 23, 0.6) !important;
}

.cardGallery {
    margin: 5%;
    border: 1px solid black;
    padding: 2%;
    box-shadow: 0 4px 27px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
}

.cardGallery .page-item a.page-link {
    color: white; /* Cambia el color del texto a blanco */
}

.textNoImagen {
    font-family: 'Roboto-Regular' !important;
    font-size: 24px !important;
    color: black !important
}

.titleGallery {
    font-family: 'BebasNeue-Regular' !important;
    font-size: 50px !important;
    color: #264A80 !important;
    font-weight: bold !important;
}
/* Media query para pantalla de celular */
@media only screen and (max-width: 767px) {
    .styleCards {
        padding: 60% 2% !important;
    }
    .cardGallery {
        margin-top: 10% !important;
    }
}

/* Media query para pantalla de tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .styleCards {
        padding: 0 !important;
    }
  
    .cardGallery {
        margin-top: 10% !important;
    }
}