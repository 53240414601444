.button-container {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 338px;
  height: 46px;
  background: #264A80;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.button-container :hover {
  cursor: pointer;
}

/* Estilos para el texto del botón */
.button-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FAFAFA;
}