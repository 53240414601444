.login {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 40px;
}

.login-container {
    align-items: center;
    width: 610px !important;
    height: 650px !important;
    padding: 50px !important;
    background-color: #fff !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #FFFFFF;
    border: 3px solid #264A80;
    border-radius: 20px;
}

.login h2 {
    font-family: "Roboto-Bold";
    text-align: center !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 22px;
    text-align: center;
    color: #161616;
}


.login h3 {
    font-family: "Roboto-Regular";
    text-align: center !important;
    font-style: normal;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 30px;
    text-align: center;
    color: #161616;
}


.input-container {
    margin-bottom: 15px !important;
}

.input-container input:focus {
    border-color: #264A80 !important;
}

.input-container label {
    font-family: "Roboto-Bold";
    font-weight: 700;
    display: block !important;
    margin-bottom: 5px !important;
}

.input-container input {
    width: 100% !important;
    padding: 8px !important;
    border: 1px solid #4EA46F !important;
    border-radius: 5px !important;
    border-radius: 50px !important;
    margin-bottom: 30px;
}

.password-input {
    position: relative !important;
}

.password-input input {
    padding-right: 30px !important;
    border-radius: 50px !important;
}

.password-input .toggle-password {
    position: absolute !important;
    top: 30% !important;
    right: 10px !important;
    transform: translateY(-50%) !important;
    cursor: pointer !important;
    color: #4EA46F !important;
}

.login button {
    font-family: "Roboto-Bold";
    width: 100% !important;
    padding: 10px !important;
    background-color: #4EA46F !important;
    border: none !important;
    border-radius: 5px !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 50px !important;
}

.login button:hover {
    background-color: #3B8252 !important;
}

.login button:focus {
    outline: none !important;
}