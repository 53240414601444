.container-home {
  margin: 50px auto;
  margin-bottom: 300px;
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.box-home {
  flex: 0 1 calc(33.33% - 40px);
  margin: 20px;
  background: rgba(103, 171, 130, 0.68);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.box-home:hover {
  cursor: pointer;
  background: #264A80;
}

.icon-home {
  font-size: 60px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.text-home {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.title-home {
  margin-top: 100px;
  font-family: 'Bebas Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.2;
  text-align: center;
  color: #264A80;
}

.separator-home {
  width: 30%;
  height: 2px;
  background-color: #264A80;
  margin: 20px auto;
}