.backgroundColor{
    background-color: #264A80 !important;
    max-width: none !important;
}

.change-color-text-footer {
    color: white !important;
    font-size: 18px !important;
}

.liColor {
    border: 1px solid #264A80 !important;
    margin-left: 10% !important;
}

.icon{
    margin-right: 3%;
}

.title {
    font-size: 24px !important;
    font-weight: bold;
    margin-left: 6%;
}

.iconYouTube {
    margin-left: 3%;
}

.title-politic {
    
}
.resolution {
    margin:2% 9% 4% 6% !important;
    font-size: 14px !important;
}
