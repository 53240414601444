.titlePayOnLine {
    font-family: 'BebasNeue-Bold';
    color: #000000;
    font-weight: bold;
}

.paragraphPay {
    color: #000000 !important;
}

