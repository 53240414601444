.titleMissionVision {
    color: #264A80 !important;
    font-family: 'BebasNeue-Regular';
    font-size: 36px !important;
}

.titleMoralValues {
    color: #000000 !important;
    font-size: 30px !important;
    font-family: 'Roboto-Regular', sans-serif;
}

.maxStyle{
    max-width: none !important;
}

.containerMission {
    background-color: #F7F7F7;
}

.containerVision {
    background-color: #FFF4CE;
}
.paragraph {
    color: #000000 !important;
}

.paragraphMoralValues {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 15px !important;
}

.colorYellowValues {
    color: #D3B574 !important;
}

.colorDefaultValues {
    color: #000000 !important;
}

.coloBlueValues {
    color: #264A80 !important; 
    opacity: 0.68;
}
