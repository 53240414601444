.header {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF;
  color: #fff;
  padding: 20px 0;
}

.img-logo {
  width: 75%;
}

.text {
  color: #4EA46F !important;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 20px !important;
}

.buttonPay {
  padding: 0.5rem 1.875rem !important;
  background-color: #FFFFFF !important;
  border-radius: 32px !important;
  border: 2px solid #4EA46F !important;
}

.nav-link {
  text-align: center;
  /* Centrar el texto horizontalmente */
}

.bg-body-tertiary {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.logout {
  span {
    margin-right: 20px;
  }
}