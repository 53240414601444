.borderRadioGallery {
    border-radius: 20px 0px 0px 20px!important;
}
.textGallery {
    font-size: 20px !important;
    font-family: 'Roboto-Regular' !important;
    color: black !important;
    font-weight: lighter !important;

}
.titleGallery {
    font-size: 24px !important;
    font-family: 'Roboto-Regular' !important;
    color: black !important;
    font-weight: bold !important;
}
.active>.page-link, .page-link.active {
    background-color: rgba(38, 74, 128, 0.85) !important;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
    background-color: rgba(38, 74, 128, 0.85) !important;
}